import React from 'react';
import { navigate } from 'gatsby';

import Layout from '../components/layout';

import '../styles/nginr.css';

const Nginr = (props) => {

    const handleClick = (id) => {
        navigate('/');
        var checkExist = setInterval(function () {
            if (document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: 'smooth'
                });
                clearInterval(checkExist);
            }
        }, 100);
    }

    return (
        <Layout location={props.location}>
            <div className='nginr'>
                <div className='nginr__top'>
                    <div className='nginr__top__content'>
                        <h1 className='nginr__top__content__title'>nginr<sup><small>®</small></sup></h1>
                        <p className='nginr__top__content__detail'>
                            Big data analytics and fusion platform enabling actionable intelligence for decision support and automation.
                            The feature rich and high-performance platform can be configured to meet domain and application specific needs.
                             A perfect fit when you are building solutions to generate actionable intelligence from real-time streams of Big Data.
                              Move beyond analytics from single homogenous datasets and instead make sense of multiple streams of heterogenous 
                              datasets with our augmented data analytics platform. Our actionable intelligence approach means that the analytics 
                              is prepared for human decision making as well as consumption by machines for automation purposes. <br/>
                              <br/>
                            The platform has been designed to meet the demanding applications with requirements such as real-time continuous intelligence, 
                            security and strong authentication, data integrity and accountability. Bring Your Own Data or use our data access services to discover, 
                            source, prepare and integrate the open data sets needed. With access to big data in place the platform will process the data using 
                            artificial intelligence and machine learning techniques. We have a diverse set of our own proprietary components with ready 
                            trained models for machine learning from a variety of data types such as text, images, audio and video. Integration of 3rd 
                            party models is supported and as well as time series and measurements from sensors such as location, pressure, temperature. 
                            Any concept identified and their interrelations are organized and recorded in a Blocknetwork. The Blocknetwork technology is 
                            developed and patented by Dataunitor.The architecture of the platform is distributed, modular and component based making it 
                            scalable by design. The multiple instances of the same module, typically thousands of them, may be active simultaneously. 
                            The components make heavy use of hardware acceleration (GPU) processing techniques. <br/>
                            <br/>
                            nginr® is provided under a Platform as a Service (PaaS) software subscription model. The PaaS service is running on a 
                            highly optimized operating system in our own datacenter. <u onClick={() => navigate('/contact')}>Get in touch</u> to discuss your needs and any special requirements 
                            such as on-premises deployment and access to source code.
                    </p>
                    </div>
                    <img className='nginr__top__image' src='/images/nginr.png' alt='nginr' />
                </div>
            </div>
        </Layout>
    );
}

export default Nginr;